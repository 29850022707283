import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import Form from '../../components/Form'

import { useFormData } from '../../hooks/gravityForms'
import { H1, H2, H3 } from '../../components/Headings'
import Container from '../../components/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import A from '../../components/A'
import Img from 'gatsby-image'

const PageTemplate = ({ data }) => {
  const page = data.allWpPage.nodes[0]
  const [cooksLogoTransparent] = data.allFile.nodes

  const allFormData = useFormData()

  const handleError = ({ values, error, reset }) => {
    //handle error
  }

  const handleSuccess = ({ values, reset, confirmations }) => {
    //handle success
  }

  return (
    <>
      <SEO />
      <Layout>
        <Container tw="grid grid-cols-1 md:(grid-cols-2) col-gap-12 py-12">
          <div tw="pb-12 md:(pb-0)">
            <H1 tw="text-3xl mb-10">{page.title}</H1>

            <Form
              id={3}
              allFormData={allFormData}
              successCallback={handleSuccess}
              errorCallback={handleError}
            />
          </div>
          <div tw="text-center max-w-sm mx-auto">
            <div tw="w-1/3 mb-10 inline-block">
              <Img fluid={cooksLogoTransparent.childImageSharp.fluid} />
            </div>
            <div
              tw="prose mx-auto"
              dangerouslySetInnerHTML={{ __html: page.content }}
            />
            <div>
              <H3 tw="text-gray-800">
                Cook's Customer <br />
                Care Center
              </H3>

              <A href="tel:8002399898" tw="block">
                800-239-9898
              </A>
              <A href="mailto:wecare@cookspest.com" tw="block">
                wecare@cookspest.com
              </A>
            </div>
          </div>
        </Container>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allFile(filter: { relativePath: { eq: "cooks-logo-transparent-bg.png" } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
      }
    }
  }
`
